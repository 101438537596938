import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import ArticlePosts from "./ArticlePosts";
import ArticleForm from "./ArticleForm";
import FeaturedCategories from "./FeaturedCategories";
import Layout from "../components/Layout/layout";
import SwitchBlog from "./SwitchBlog";
import "./Blog.scss";
import Seo from "../components/Seo/seo";

interface Blog {
  pageContext;
  props;
  title;
  path: string;
}

const Blog: React.FC<Blog> = (props) => {
  const [numberOfNewsItems, setNumberOfNewsItems] = useState(10);
  const [numberOfComparisonItems, setNumberOfComparisonItems] = useState(10);
  const [numberOfPhnoesItems, setNumberOfPhnoesItems] = useState(10);
  const [numberOfReviewsItems, setNumberOfReviewsItems] = useState(10);
  const [numberOfSpecificationItems, setNumberOfSpecificationItems] =
    useState(10);
  const [numberOfGadgetItems, setNumberOfGadgetItems] = useState(10);

  const { pageContext } = props;

  const data = useStaticQuery(graphql`
    {
      allStrapiPost(sort: { fields: date, order: DESC }) {
        totalCount
        nodes {
          slug
          date
          Title
          id
          description {
            data {
              description
            }
          }
          blog_categories {
            category
            slug
          }
          admin_users {
            username
          }
          blogPara
          ReadTime
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 720)
              }
            }
          }
        }
      }
    }
  `);

  const newsPosts = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "news"
    );
  });

  const comparisonPosts = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "comparison"
    );
  });
  const phonesPosts = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "phones"
    );
  });

  const reviewsPosts = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "reviews"
    );
  });

  const specPosts = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "spec"
    );
  });

  const gadgetPost = data.allStrapiPost.nodes.filter((post) => {
    return post.blog_categories.some(
      (category) => category.category.toLowerCase() === "gadgets"
    );
  });

  const getTotalCount = (): String => {
    switch (pageContext.title) {
      case "News":
        return `${newsPosts.length} posts`;
      case "Phones":
        return `${phonesPosts.length} posts`;
      case "Comparison":
        return `${comparisonPosts.length} posts`;
      case "Reviews":
        return `${reviewsPosts.length} posts`;
      case "Spec":
        return `${specPosts.length} posts`;
      case "Gadgets":
        return `${gadgetPost.length} posts`;
      default:
        return `0 posts`;
    }
  };

  const metaDesc =
    "Get your insights on the Latest Technology, Gadgets, Mobile Phones with specs and reviews that guide you for the best and honest purchase.";

  return (
    <Layout>
      <div className="review py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="page_header pb-5">
                <>
                  <span>{pageContext.browsingCategory}</span>
                  <h1 className="pt-3">{pageContext.title}</h1>
                  <div className="archive_count">{getTotalCount()}</div>
                </>
              </div>
              {pageContext.blogSlug === "/category/news" ? (
                <>
                  <Seo title="News Archives - Phonera" description={metaDesc} />

                  {newsPosts.slice(0, numberOfNewsItems ?? 3).map((e) => {
                    return (
                      <SwitchBlog
                        slug={e.slug}
                        image={e.image}
                        category={e.blog_categories}
                        title={e.Title}
                        blogPara={e.blogPara}
                        date={new Date(e.date)
                          .toDateString()
                          .split(" ")
                          .slice(1, 4)
                          .toString()
                          .replace(",", " ")}
                        adminName={e.admin_users[0].username}
                        adminLink={e.admin_users[0].username.toLowerCase().replace(" ", "-")}
                        readTime={e.ReadTime}
                      />
                    );
                  })}
                  <div className={newsPosts.length >= 9 ? "pt-5" : "d-none"}>
                    {newsPosts.length >= numberOfNewsItems && (
                      <button
                        className="cs-load-more mb-4"
                        onClick={() =>
                          setNumberOfNewsItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {pageContext.blogSlug === "/category/comparison" ? (
                <>
                  <Seo
                    title="Comparison Archives - Phonera"
                    description={metaDesc}
                  />
                  {comparisonPosts
                    .slice(0, numberOfComparisonItems ?? 3)
                    .map((e) => {
                      return (
                        <SwitchBlog
                          slug={e.slug}
                          image={e.image}
                          category={e.blog_categories}
                          title={e.Title}
                          blogPara={e.blogPara}
                          date={new Date(e.date)
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .toString()
                            .replace(",", " ")}
                          adminName={e.admin_users[0].username}
                          adminLink={e.admin_users[0].username.toLowerCase().replace(
                            " ",
                            "-"
                          )}
                          readTime={e.ReadTime}
                        />
                      );
                    })}
                  <div
                    className={comparisonPosts.length > 10 ? "pt-5" : "d-none"}
                  >
                    {comparisonPosts.length >= numberOfComparisonItems && (
                      <button
                        className="cs-load-more"
                        onClick={() =>
                          setNumberOfComparisonItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {pageContext.blogSlug === "/category/phones" ? (
                <>
                  <Seo
                    title="Phones Archives - Phonera"
                    description={metaDesc}
                  />
                  {phonesPosts
                    .slice(0, numberOfPhnoesItems ?? 3)
                    .map((e, i) => {
                      return (
                        <SwitchBlog
                          slug={e.slug}
                          image={e.image}
                          category={e.blog_categories}
                          title={e.Title}
                          blogPara={e.blogPara}
                          date={new Date(e.date)
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .toString()
                            .replace(",", " ")}
                          adminName={e.admin_users[0].username}
                          adminLink={e.admin_users[0].username.toLowerCase().replace(
                            " ",
                            "-"
                          )}
                          readTime={e.ReadTime}
                        />
                      );
                    })}
                  <div className={phonesPosts.length > 10 ? "pt-5" : "d-none"}>
                    {phonesPosts.length >= numberOfPhnoesItems && (
                      <button
                        className="cs-load-more"
                        onClick={() =>
                          setNumberOfPhnoesItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {pageContext.blogSlug === "/category/reviews" ? (
                <>
                  <Seo title="Reviews Archives - Phonera" />
                  {reviewsPosts.slice(0, numberOfReviewsItems ?? 3).map((e) => {
                    return (
                      <SwitchBlog
                        slug={e.slug}
                        image={e.image}
                        category={e.blog_categories}
                        title={e.Title}
                        blogPara={e.blogPara}
                        date={new Date(e.date)
                          .toDateString()
                          .split(" ")
                          .slice(1, 4)
                          .toString()
                          .replace(",", " ")}
                        adminName={e.admin_users[0].username}
                        adminLink={e.admin_users[0].username.toLowerCase().replace(" ", "-")}
                        readTime={e.ReadTime}
                      />
                    );
                  })}
                  <div className={reviewsPosts.length > 10 ? "pt-5" : "d-none"}>
                    {reviewsPosts.length >= numberOfReviewsItems && (
                      <button
                        className="cs-load-more"
                        onClick={() =>
                          setNumberOfReviewsItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {pageContext.blogSlug === "/category/spec" ? (
                <>
                  <Seo title="Spec Archives - Phonera" description={metaDesc} />
                  {specPosts
                    .slice(0, numberOfSpecificationItems ?? 3)
                    .map((e) => {
                      return (
                        <SwitchBlog
                          slug={e.slug}
                          image={e.image}
                          category={e.blog_categories}
                          title={e.Title}
                          blogPara={e.blogPara}
                          date={new Date(e.date)
                            .toDateString()
                            .split(" ")
                            .slice(1, 4)
                            .toString()
                            .replace(",", " ")}
                          adminName={e.admin_users[0].username}
                          adminLink={e.admin_users[0].username.toLowerCase().replace(
                            " ",
                            "-"
                          )}
                          readTime={e.ReadTime}
                        />
                      );
                    })}
                  <div className={specPosts.length > 10 ? "pt-5" : "d-none"}>
                    {specPosts.length >= numberOfSpecificationItems && (
                      <button
                        className="cs-load-more"
                        onClick={() =>
                          setNumberOfSpecificationItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
              {pageContext.blogSlug === "/category/gadgets" ? (
                <>
                  {gadgetPost.slice(0, numberOfGadgetItems ?? 3).map((e) => {
                    return (
                      <SwitchBlog
                        slug={e.slug}
                        image={e.image}
                        category={e.blog_categories}
                        title={e.Title}
                        blogPara={e.blogPara}
                        date={new Date(e.date)
                          .toDateString()
                          .split(" ")
                          .slice(1, 4)
                          .toString()
                          .replace(",", " ")}
                        adminName={e.admin_users[0].username}
                        adminLink={e.admin_users[0].username.toLowerCase().replace(" ", "-")}
                        readTime={e.ReadTime}
                      />
                    );
                  })}

                  <div className={comparisonPosts < 10 ? "pt-5" : "d-none"}>
                    {comparisonPosts.length >= numberOfGadgetItems && (
                      <button
                        className="cs-load-more"
                        onClick={() =>
                          setNumberOfGadgetItems((state) => state + 10)
                        }
                      >
                        Load More
                      </button>
                    )}
                  </div>
                </>
              ) : null}
            </div>

            <div className="col-lg-4 px-lg-5 mt-lg-0 mt-md-5">
              <div className="h-100">
                <div className="d-flex flex-column flex-sm-row flex-lg-column set-sticky">
                  <div>
                    <ArticleForm />
                  </div>
                  <div className="pt-lg-3 pt-sm-0 col-lg-12 col-sm-6  scrolling-effect">
                    <ArticlePosts />
                    <FeaturedCategories />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default React.memo(Blog);